import Pages from './pages';

function App() {
  return (
    <div>
            <Pages />

    </div>
  );
}

export default App;

import {Link} from 'react-router-dom';

export default function Footer() {

    return (

<footer className="p-4 bg-slate-100 dark:text-white dark:bg-slate-900 sm:p-6">
    <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center text-gray-800 dark:text-gray-300">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="150" height="80" viewBox="0 0 638 391"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,391.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M3300 3771 c-23 -45 -9 -101 27 -101 48 0 92 77 61 108 -20 20 -76
15 -88 -7z"/>
<path d="M3097 3718 c-47 -64 -57 -83 -57 -106 0 -36 21 -22 51 34 30 54 57
77 77 65 10 -6 5 -21 -20 -62 -41 -67 -63 -162 -51 -210 14 -51 0 -96 -43
-140 l-38 -39 -1331 0 -1330 0 -63 -30 c-76 -36 -115 -75 -153 -156 l-29 -61
0 -1338 0 -1338 24 -53 c36 -77 97 -139 164 -165 l57 -22 1380 2 c1311 3 1382
4 1428 21 26 10 64 30 82 45 48 36 101 136 115 215 8 46 10 443 8 1345 -3
1084 -5 1283 -17 1301 -13 19 -12 25 7 49 12 15 22 34 22 42 0 8 11 25 25 39
29 29 47 24 102 -30 26 -24 33 -39 33 -69 0 -44 41 -129 99 -207 l41 -54 0
-472 0 -472 25 -6 c17 -4 25 -14 26 -29 0 -14 2 -17 6 -7 3 8 11 21 17 29 8
10 10 79 7 221 -2 114 1 210 5 213 5 3 41 7 79 8 l70 4 5 -220 5 -220 38 -3
c25 -2 37 1 37 10 3 195 -2 936 -6 958 -6 26 -11 30 -40 30 l-34 0 0 -195 0
-195 -79 0 -78 0 -2 153 c-1 195 -6 230 -34 242 -13 6 -38 43 -61 88 -57 112
-59 162 -12 300 20 58 36 119 36 135 0 16 6 35 14 41 12 10 18 5 32 -25 18
-37 17 -46 -13 -137 -5 -16 -1 -18 33 -15 36 3 40 7 53 43 21 60 8 111 -40
158 -37 37 -41 39 -91 34 -29 -3 -79 -10 -112 -16 -51 -10 -62 -9 -90 7 -23
14 -31 25 -31 48 -1 22 -6 29 -20 30 -11 1 -32 2 -48 3 -21 1 -34 12 -57 48
-17 26 -30 54 -30 62 -1 22 -55 80 -86 91 -49 19 -81 12 -107 -22z m210 -185
c12 -26 28 -41 48 -47 17 -5 42 -25 57 -46 18 -27 37 -40 65 -47 l38 -9 0 -69
c0 -75 2 -73 -58 -59 -37 9 -81 -31 -114 -106 -23 -49 -27 -53 -37 -36 -6 11
-24 35 -40 53 -27 31 -30 43 -33 116 -3 53 -11 98 -25 130 -21 48 -21 50 -3
84 18 33 59 73 76 73 4 0 16 -17 26 -37z m-134 -170 c19 -37 28 -105 18 -122
-8 -12 -14 -12 -40 -1 -41 17 -43 22 -26 64 8 19 15 47 15 61 0 33 16 32 33
-2z m-1189 -1104 c49 -14 116 -75 164 -153 44 -70 55 -97 77 -182 25 -102 26
-101 -57 -108 -40 -3 -86 -7 -103 -8 -27 -3 -31 1 -45 41 -21 62 -67 111 -111
117 -28 5 -41 1 -67 -21 -51 -42 -75 -99 -80 -186 -5 -94 15 -164 63 -214 60
-64 141 -43 182 47 11 24 23 64 27 89 l7 46 92 -4 c101 -5 121 -9 113 -21 -3
-5 -10 -42 -16 -83 -28 -186 -110 -337 -214 -392 -59 -31 -165 -30 -226 1 -57
30 -125 96 -140 136 -7 17 -16 40 -21 51 -31 72 -41 108 -54 183 -26 148 -13
280 41 427 71 189 210 278 368 234z m-1131 -25 c46 -23 105 -82 129 -129 31
-60 31 -215 -1 -310 -38 -114 -79 -182 -139 -231 -43 -35 -62 -44 -93 -44 -27
0 -41 5 -48 18 -11 21 -2 38 67 126 37 46 45 65 53 123 16 104 -4 263 -32 263
-4 0 -13 -26 -19 -57 -7 -32 -25 -121 -41 -198 -16 -77 -38 -192 -49 -255 -23
-130 -60 -237 -93 -267 -12 -11 -36 -28 -54 -38 -31 -18 -33 -18 -54 1 -18 17
-20 27 -16 84 5 63 59 333 112 555 14 61 29 136 32 169 6 58 6 58 -18 51 -49
-16 -54 -13 -53 32 1 50 22 78 79 104 53 25 192 26 238 3z m377 -328 c0 -360
4 -396 46 -396 13 0 25 1 26 3 1 1 5 164 8 362 l5 360 96 3 c94 3 97 2 102
-21 10 -37 -2 -670 -13 -744 -6 -37 -26 -100 -44 -140 -29 -59 -42 -75 -80
-98 -144 -84 -289 5 -325 200 -7 36 -11 205 -11 432 l0 373 95 0 95 0 0 -334z
m1268 -173 l2 -503 -101 0 -102 0 6 503 c4 276 6 503 7 505 0 1 42 1 93 0 l92
-3 3 -502z m540 359 l3 -143 -73 2 -73 2 -1 -364 -1 -364 -101 -3 -102 -3 0
366 0 365 -75 0 -75 0 0 114 c0 62 3 128 6 145 l7 31 241 -2 241 -3 3 -143z"/>
<path d="M3589 3591 c-22 -21 -39 -48 -39 -59 0 -56 29 -66 86 -31 39 24 54
47 54 81 0 58 -48 63 -101 9z"/>
<path d="M4447 2833 c-4 -3 -7 -224 -7 -489 0 -468 1 -484 19 -494 11 -5 78
-10 150 -10 l131 0 0 85 0 84 -102 3 -103 3 0 130 0 130 98 -4 98 -3 -3 88 -3
89 -98 3 -97 3 1 110 1 111 101 -4 102 -3 3 88 3 87 -144 0 c-79 0 -147 -3
-150 -7z"/>
<path d="M5890 2839 c0 -2 -1 -224 -2 -494 l0 -490 26 -13 c18 -9 31 -10 43
-3 16 9 18 26 14 195 l-3 186 62 0 c55 0 64 3 89 30 51 57 66 119 66 280 0
142 -1 146 -32 212 -17 37 -41 72 -54 79 -21 11 -209 27 -209 18z m135 -180
c4 -6 13 -8 21 -5 18 7 40 -27 55 -84 9 -37 9 -55 -5 -102 -20 -68 -33 -80
-83 -76 l-38 3 -3 138 -3 137 25 0 c14 0 28 -5 31 -11z"/>
<path d="M5177 2823 c-4 -3 -7 -223 -7 -488 l0 -483 31 -6 c17 -3 82 -6 145
-6 l113 0 3 53 c2 28 -1 67 -6 85 l-9 32 -81 0 c-45 0 -85 4 -91 8 -6 4 -10
144 -10 382 0 206 -3 387 -8 403 -6 22 -13 27 -41 27 -18 0 -36 -3 -39 -7z"/>
<path d="M5235 1625 c-77 -85 -89 -315 -21 -441 19 -38 63 -84 79 -84 15 0 70
-55 86 -87 23 -44 7 -175 -23 -195 -6 -4 -23 -8 -37 -8 -33 0 -59 37 -66 92
-3 21 -10 41 -14 44 -5 3 -9 14 -9 25 0 13 -7 19 -24 19 -37 0 -50 -23 -39
-68 6 -21 8 -52 6 -68 -6 -39 32 -137 69 -180 66 -75 151 -46 204 69 28 59 29
69 29 202 0 126 -2 145 -24 192 -22 49 -80 112 -161 173 -29 22 -33 32 -37 80
-10 128 87 167 117 47 6 -23 13 -47 15 -54 5 -15 49 -27 70 -19 39 15 -1 185
-61 254 -32 38 -40 42 -81 42 -39 0 -50 -5 -78 -35z"/>
<path d="M3682 1153 l3 -498 75 -3 c107 -5 144 9 175 63 28 47 62 153 71 225
13 95 15 303 5 384 -22 160 -80 294 -136 315 -17 6 -68 11 -113 11 l-82 0 2
-497z m198 297 c18 -17 36 -45 41 -63 13 -41 12 -429 0 -482 -14 -57 -53 -86
-111 -83 l-45 3 -3 315 c-1 173 0 320 3 327 2 8 21 13 44 13 31 0 47 -7 71
-30z"/>
<path d="M4447 1644 c-4 -4 -7 -227 -7 -495 l0 -489 26 -6 c15 -4 81 -7 148
-8 l121 -1 3 88 3 87 -106 0 -105 0 0 131 0 130 100 -3 100 -3 0 87 0 87 -97
3 -98 3 0 109 0 109 100 1 100 1 0 85 0 85 -141 3 c-77 1 -143 -1 -147 -4z"/>
<path d="M5920 1643 c-5 -92 3 -980 8 -985 4 -4 21 -10 38 -14 l31 -6 6 179
c5 173 21 273 43 280 13 4 31 -57 83 -271 29 -122 42 -162 57 -170 10 -6 22
-15 25 -19 3 -5 14 -1 23 9 14 14 16 27 11 63 -11 76 -61 290 -105 446 -11 39
-20 82 -20 96 0 30 76 219 94 234 7 6 16 44 20 87 l7 76 -43 -1 c-47 -2 -33
23 -138 -247 -26 -68 -52 -126 -57 -127 -6 -2 -8 50 -5 131 2 74 1 159 -2 190
-7 56 -8 56 -42 56 -19 0 -34 -3 -34 -7z"/>
<path d="M6151 1284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
</g>
</svg>
            </Link>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
                <ul className="text-gray-600 dark:text-gray-400">
                    <li className="mb-4">
                        <Link to="/blog" className="hover:underline">Blogs</Link>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Community</h2>
                <ul className="text-gray-600 dark:text-gray-400">
                    <li className="mb-4">
                        <a href="https://facebook.com/groups/PUCITHelpDesk" target="_blank" rel='noreferrer' className="hover:underline ">Facebook</a>
                    </li>
                    <li>
                        <a href="https://discord.gg/eN6gMQaSvJ" target="_blank" rel='noreferrer' className="hover:underline">Discord</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Project</h2>
                <ul className="text-gray-600 dark:text-gray-400">
                    <li className="mb-4">
                        <Link to="/about" className="hover:underline">About</Link>
                    </li>
                    <li>
                        <Link to="/contact" className="hover:underline">Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
    <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="/" className="hover:underline">PUCIT Help Desk</a>.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a href="https://fb.com/PUCITHD" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Facebook page</span>
            </a>
            <a href="https://instagram.com/pucithelpdesk" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Instagram page</span>
            </a>
            <a href="https://twitter.com/PUCITHelpDesk" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path></svg>
                <span className="sr-only">Twitter page</span>
            </a>
            <a href="https://github.com/PUCITHD" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd"></path></svg>
                <span className="sr-only">GitHub account</span>
            </a>
        </div>
    </div>
</footer>


    );
}